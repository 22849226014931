import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'normalize.css';
import './index.css';
import App from './App';
import store from './store/store';

// context providers
import UserProvider from './context/userContext';
import { AuthProvider } from './context/authContext';
import { WorkspaceProvider } from './context/workspaceContext';
import { AccountProvider } from './context/accountContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <AuthProvider>
                    <UserProvider>
                        <AccountProvider>
                            <WorkspaceProvider>
                                <App />
                            </WorkspaceProvider>
                        </AccountProvider>
                    </UserProvider>
                </AuthProvider>
            </Router>
        </Provider>
    </React.StrictMode>
);
