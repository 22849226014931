/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyGetWorkspaceByIdQuery } from '../../store/services/workspaceApi';
import { useUser } from '../userContext';

const WorkspaceContext = createContext();

export function useWorkspace() {
    return useContext(WorkspaceContext);
}

export function WorkspaceProvider({ children }) {
    const { workspaces } = useUser();
    const [workspaceId, setWorkspaceId] = useState(workspaces ? workspaces[0]?.id : '');
    const [getWorkspaceById, { data, isLoading, isFetching }] = useLazyGetWorkspaceByIdQuery();
    const [hasWorkspaces, setHasWorkspaces] = useState(Boolean(workspaces?.[0]));

    useEffect(() => {
        async function getWorkspace(id) {
            try {
                const result = await getWorkspaceById(id).unwrap();
                if (Object.keys(result).length > 0) {
                    setHasWorkspaces(true);
                } else {
                    setHasWorkspaces(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (workspaceId) {
            getWorkspace(workspaceId);
        }
    }, [workspaceId]);

    const setWorkspace = (id) => {
        setWorkspaceId(id);
        setHasWorkspaces(true);
    };

    const values = useMemo(
        () => ({
            ...data,
            setWorkspace,
            isFetching,
            hasWorkspaces
        }),
        [data, isFetching]
    );

    return (
        <WorkspaceContext.Provider value={values}>
            {!isLoading && children}
        </WorkspaceContext.Provider>
    );
}
