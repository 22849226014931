/* eslint-disable react/prop-types */
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useAccount } from '../context/accountContext';
import { useUser } from '../context/userContext';
import theme from '../theme';

export default function ThemeProviderHoc({ children }) {
    const { settings: userSettings } = useUser();
    const { settings: accountSettings } = useAccount();

    const accountTheme = accountSettings?.theme;
    const userTheme = userSettings?.theme;

    let selectedTheme = accountTheme ?? 'blueDark';

    if (userTheme !== undefined && userTheme === '') selectedTheme = userTheme;

    console.log(JSON.stringify(theme[selectedTheme], null, 2));

    let currentTheme = createTheme(theme[selectedTheme]);
    currentTheme = responsiveFontSizes(currentTheme);

    return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
