/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useUser } from '../userContext';
import { useLazyGetAccountByIdQuery } from '../../store/services/accountApi';

const AccountContext = createContext();

const useAccount = () => {
    return useContext(AccountContext);
};

function AccountProvider({ children }) {
    const { accountId } = useUser();
    const [getAccountById, { data, isLoading }] = useLazyGetAccountByIdQuery();
    useEffect(() => {
        async function getAccountByIdAsync(id) {
            try {
                await getAccountById(id);
            } catch (error) {
                return null;
            }
            return null;
        }
        if (accountId) {
            getAccountByIdAsync(accountId);
        }
    }, [accountId]);

    function isAccountAdmin(user) {
        if (data.users[user].type === 'owner' || data.users[user].type === 'admin') {
            return true;
        }
        return false;
    }

    const values = useMemo(() => {
        return {
            ...data,
            isAccountAdmin
        };
    }, [data]);
    return (
        <AccountContext.Provider value={values}>{!isLoading && children}</AccountContext.Provider>
    );
}

export { AccountProvider, useAccount, AccountContext };
