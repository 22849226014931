/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { userApi } from '../../store/services/userApi';
import {
    auth,
    createUserWithEmailAndPassword,
    getIdToken,
    onAuthStateChanged,
    setPersistence,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signOut,
    browserSessionPersistence,
    sendPasswordResetEmail
} from '../../util/firebase';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    // ! maybe remove the signup method
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function signInWithToken(token) {
        return signInWithCustomToken(auth, token);
    }

    function login(email, password, remember) {
        if (!remember) {
            setPersistence(auth, browserSessionPersistence);
        }
        return signInWithEmailAndPassword(auth, email, password);
    }

    function forgotPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function refreshToken() {
        return getIdToken(currentUser);
    }

    function signout() {
        userApi.util.resetApiState();
        signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            auth,
            (user) => {
                setCurrentUser(user);
                setLoading(false);
            },
            []
        );
        return unsubscribe;
    });

    const values = useMemo(
        () => ({
            currentUser,
            signup,
            login,
            signout,
            signInWithToken,
            refreshToken,
            forgotPassword
        }),
        [currentUser]
    );

    return <AuthContext.Provider value={values}>{!loading && children}</AuthContext.Provider>;
}
