/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Navbar

export const NavBarContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    position: absolute;
    right: 1em;
    top: 10px;
    z-index: 999;
`;
