/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import loadable from '@loadable/component';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from './context/authContext';
import ContextDebugger from './util/ContextDebugger';
// import theme from './theme';
// import { useAccount } from './context/accountContext';
import ThemeProvider from './hocs/ThemeProvider';
import NavBar from './components/NavBar/NavBar';

// pages
const FourOFour = loadable(() => import('./pages/FourOFour'));
const Main = loadable(() => import('./pages/Main'));
const EditTemplates = loadable(() => import('./pages/EditTemplate'));
const TemplateList = loadable(() => import('./pages/Templates'));
const Documents = loadable(() => import('./pages/Documents'));
const Settings = loadable(() => import('./pages/Settings'));
const Account = loadable(() => import('./pages/Account'));
const Storage = loadable(() => import('./pages/Settings/Storage'));
const Webhooks = loadable(() => import('./pages/Settings/Webhooks'));
const Workspace = loadable(() => import('./pages/Settings/Workspace'));
const WorkspaceUsers = loadable(() => import('./pages/Settings/Users'));
const Profile = loadable(() => import('./pages/Profile'));
const Logs = loadable(() => import('./pages/Settings/Logs'));
const Login = loadable(() => import('./pages/Login'));
const Signup = loadable(() => import('./pages/Signup'));
const ProtectedRoute = loadable(() => import('./components/PrivateRoute'));
const ErrorBoundary = loadable(() => import('./components/ErrorBoundary'));

function App() {
    const { currentUser } = useAuth();
    return (
        <ThemeProvider>
            <ErrorBoundary>
                <CssBaseline />
                <ContextDebugger />
                <NavBar />
                <Box className="App">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute isAllowed={!!currentUser} redirectPath="/login">
                                    <Main />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="templates" element={<TemplateList />} />
                            <Route path="templates/:templateId" element={<EditTemplates />} />
                            <Route path="documents" element={<Documents />} />
                            <Route path="settings" element={<Settings />}>
                                <Route path="workspace" element={<Workspace />} />
                                <Route path="storage" element={<Storage />} />
                                <Route path="users" element={<WorkspaceUsers />} />
                                <Route path="webhooks" element={<Webhooks />} />
                                <Route path="logs" element={<Logs />} />
                            </Route>
                            <Route path="account" element={<Account />} />
                            <Route path="profile" element={<Profile />} />
                        </Route>
                        {/* unprotected open always */}
                        <Route path="login" element={<Login />} />
                        <Route path="signup" element={<Signup />} />
                        <Route path="*" element={<FourOFour />} />
                    </Routes>
                </Box>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
