/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Badge,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    ListItemIcon,
    Tooltip
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

import { NavBarContainer } from './styles';
import { auth } from '../../util/firebase';
import { useUser } from '../../context/userContext';

export default function NavBar() {
    const user = useUser();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!auth.currentUser) return null;

    return (
        <NavBarContainer>
            <Tooltip title="Go to Docs">
                <IconButton>
                    <DescriptionIcon />
                </IconButton>
            </Tooltip>
            <IconButton>
                <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon color="action" />
                </Badge>
            </IconButton>
            <IconButton onClick={handleClick}>
                <Avatar alt={user?.public?.firstName} src={user?.public?.profileImageUrl}>
                    J
                </Avatar>
            </IconButton>
            <NavBarAvatarMenu open={open} handleClose={handleClose} anchorEl={anchorEl} />
        </NavBarContainer>
    );
}

function NavBarAvatarMenu({ open, handleClose, anchorEl }) {
    const navigate = useNavigate();
    return (
        <Menu
            anchorEl={anchorEl}
            id="navbar-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    minWidth: '240px',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => navigate('/profile')}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                Profile
            </MenuItem>
            <MenuItem onClick={() => navigate('/account')}>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>
                My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigate('/account?settings')}>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem onClick={() => auth.signOut()}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    );
}
