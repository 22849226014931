import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { templateApi } from './services/templateApi';
import { settingsApi } from './services/settingsApi';
import { userApi } from './services/userApi';
import { statisticsApi } from './services/statsApi';
import { workspaceApi } from './services/workspaceApi';
import { authApi } from './services/authApi';
import { accountApi } from './services/accountApi';
import { extensionsApi } from './services/extensionsApi';
import { documentsApi } from './services/documentsApi';
import { chatApi } from './services/chatApi';

const store = configureStore({
    reducer: {
        [templateApi.reducerPath]: templateApi.reducer,
        [settingsApi.reducerPath]: settingsApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [statisticsApi.reducerPath]: statisticsApi.reducer,
        [workspaceApi.reducerPath]: workspaceApi.reducer,
        [workspaceApi.reducerPath]: workspaceApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [extensionsApi.reducerPath]: extensionsApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            templateApi.middleware,
            settingsApi.middleware,
            userApi.middleware,
            statisticsApi.middleware,
            workspaceApi.middleware,
            authApi.middleware,
            accountApi.middleware,
            extensionsApi.middleware,
            documentsApi.middleware,
            chatApi.middleware
        )
});

setupListeners(store.dispatch);

export default store;
