import { createContext, useContext, useEffect, useMemo } from 'react';
import { useLazyGetUserByIdQuery } from '../../store/services/userApi';
import { useAuth } from '../authContext';

export const UserContext = createContext();

// eslint-disable-next-line react/prop-types
export default function UserProvider({ children }) {
    const { currentUser = {} } = useAuth();
    const [getUserById, { data, isLoading }] = useLazyGetUserByIdQuery();
    // TODO: replace with authenticated user id
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-unused-vars
    useEffect(() => {
        async function getUserData() {
            if (currentUser) {
                try {
                    await getUserById(currentUser.uid);
                } catch (error) {
                    return null;
                }
            }
            return null;
        }
        getUserData();
    }, [currentUser]);
    const values = useMemo(() => {
        return { ...data };
    }, [data]);

    return <UserContext.Provider value={values}>{!isLoading && children}</UserContext.Provider>;
}

export const useUser = () => {
    return useContext(UserContext);
};
