import { initializeApp } from 'firebase/app';
import {
    createUserWithEmailAndPassword,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    getIdToken,
    getAuth,
    setPersistence,
    browserSessionPersistence,
    sendPasswordResetEmail
} from 'firebase/auth';

import firebaseConfig from './firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    app,
    auth,
    createUserWithEmailAndPassword,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    getIdToken,
    setPersistence,
    sendPasswordResetEmail,
    browserSessionPersistence
};
