const blue = {
    palette: {
        mode: 'light',
        primary: {
            main: 'rgb(22, 177, 255)'
        },
        secondary: {
            main: '#F97B66'
        },
        background: {
            default: '#EAEBEE',
            dark: '#000018',
            paper: '#F2F2F2'
        },
        text: {
            primary: 'rgba(0,0,0,0.87)',
            secondary: 'rgba(0,0,0,0.6)',
            disabled: 'rgba(0,0,0,0.37)'
        }
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'mobile', 'tablet', 'laptop', 'desktop'],
        values: {
            // xs: 0,
            // sm: 600,
            // md: 900,
            // lg: 1200,
            // xl: 1536,
            mobile: 0,
            tablet: 641,
            laptop: 1441,
            desktop: 2161
        }
    }
};

const blueDark = {
    palette: {
        mode: 'dark',
        primary: {
            main: 'rgb(22, 177, 255)'
        },
        secondary: {
            main: '#F97B66'
        },
        background: {
            default: '#28243D',
            dark: '#000018',
            paper: 'rgb(49, 45, 75)'
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: 'rgba(255,255,255,0.6)',
            disabled: 'rgba(255,255,255,0.37)'
        }
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'mobile', 'tablet', 'laptop', 'desktop'],
        values: {
            // xs: 0,
            // sm: 600,
            // md: 900,
            // lg: 1200,
            // xl: 1536,
            mobile: 0,
            tablet: 641,
            laptop: 1441,
            desktop: 2161
        }
    }
};
const purple = {
    palette: {
        mode: 'light',
        primary: {
            main: 'rgb(145, 85, 253)'
        },
        secondary: {
            main: '#F97B66'
        },
        background: {
            default: '#EAEBEE',
            paper: '#F2f2f2'
        },
        text: {
            primary: 'rgba(0,0,0,0.87)',
            disabled: 'rgba(0,0,0,0.37)'
        },
        buttonWhite: {
            main: 'rgba(0,0,0,0.87)',
            contrastText: 'rgba(0,0,0,0.87)'
        }
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1440,
            desktop: 2160
        }
    }
};

const purpleDark = {
    palette: {
        mode: 'dark',
        primary: {
            main: 'rgb(145, 85, 253)'
        },
        secondary: {
            main: '#F97B66'
        },
        background: {
            default: '#28243D',
            paper: 'rgb(49, 45, 75)'
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            disabled: 'rgba(255,255,255,0.37)'
        },
        buttonWhite: {
            main: 'rgba(255,255,255,0.87)',
            contrastText: 'rgba(255,255,255,0.87)'
        }
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1440,
            desktop: 2160
        }
    }
};

export default { purple, blue, blueDark, purpleDark };
