/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { Switch } from '@mui/material';

import { useAccount } from '../context/accountContext';
import { useUser } from '../context/userContext';
import { useWorkspace } from '../context/workspaceContext';
import { useAuth } from '../context/authContext';

export default function ContextDebugger() {
    if (process.env.NODE_ENV !== 'development') return null;
    const [displayAll, setDisplayAll] = useState(false);
    const [userOpen, setUserOpen] = useState(false);
    const [workspaceOpen, setWorkspaceOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [authUserOpen, setAuthUserOpen] = useState(false);

    const handleDisplayAll = () => setDisplayAll((prev) => !prev);

    const handleUserOpen = (e) => {
        if (e.type !== 'change') setUserOpen((prev) => !prev);
    };
    const handleAccountOpen = (e) => {
        if (e.type !== 'change') setAccountOpen((prev) => !prev);
    };
    const handleWorkspaceOpen = (e) => {
        if (e.type !== 'change') setWorkspaceOpen((prev) => !prev);
    };
    const handleAuthUserOpen = (e) => {
        if (e.type !== 'cange') setAuthUserOpen((prev) => !prev);
    };

    const { currentUser } = useAuth();
    const user = useUser();
    const workspace = useWorkspace();
    const account = useAccount();

    return (
        <div
            style={{
                position: 'absolute',
                right: '10px',
                bottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                zIndex: 99999
            }}
        >
            {' '}
            <div style={{ alignSelf: 'flex-end' }}>
                Debug context
                <Switch checked={displayAll} onChange={handleDisplayAll} />
            </div>
            <div style={{ display: displayAll ? 'block' : 'none' }}>
                <div
                    style={{
                        width: '400px',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        paddingLeft: '1em',
                        zIndex: 1,
                        borderRadius: '5px'
                    }}
                >
                    <div
                        onClick={handleAuthUserOpen}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Switch checked={authUserOpen} onChange={handleAuthUserOpen} />
                        <p>Auth User currentUser object</p>
                    </div>
                    <div
                        style={{
                            height: authUserOpen ? '300px' : '0',
                            overflowY: 'auto'
                        }}
                    >
                        <pre>{JSON.stringify(currentUser, null, 4)}</pre>
                    </div>
                </div>
                <div
                    style={{
                        width: '400px',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        paddingLeft: '1em',
                        zIndex: 1,
                        borderRadius: '5px'
                    }}
                >
                    <div
                        onClick={handleWorkspaceOpen}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Switch checked={workspaceOpen} onChange={handleWorkspaceOpen} />
                        <p>Workspaces Contenxt value</p>
                    </div>
                    <div
                        style={{
                            height: workspaceOpen ? '300px' : '0',
                            overflowY: 'auto'
                        }}
                    >
                        <pre>{JSON.stringify(workspace, null, 4)}</pre>
                    </div>
                </div>
                <div
                    style={{
                        width: '400px',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        paddingLeft: '1em',
                        zIndex: 1,
                        borderRadius: '5px'
                    }}
                >
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        onClick={handleUserOpen}
                    >
                        <Switch checked={userOpen} onChange={handleUserOpen} />
                        <p>User Contenxt value</p>
                    </div>
                    <div
                        style={{
                            height: userOpen ? '300px' : '0px',
                            overflowY: 'auto'
                        }}
                    >
                        <pre>{JSON.stringify(user, null, 4)}</pre>
                    </div>
                </div>
                <div
                    style={{
                        width: '400px',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        paddingLeft: '1em',
                        zIndex: 1,
                        borderRadius: '5px'
                    }}
                >
                    <div
                        onClick={handleAccountOpen}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <Switch checked={accountOpen} onChange={handleAccountOpen} />
                        <p>Account Context values</p>
                    </div>
                    <div
                        style={{
                            height: accountOpen ? '250px' : '0px',
                            overflowY: 'auto'
                        }}
                    >
                        <pre>{JSON.stringify(account, null, 4)}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
}
